function callBackForm() {
  document.getElementById('callback-overlay').style.opacity = 1;
  document.getElementById('callback-overlay').style.visibility = 'visible';
  document.getElementById('callback-popup').style.opacity = 1;
  document.getElementById('callback-popup').style.visibility = 'visible';
}

function closeCallback() {
  document.getElementById('callback-overlay').style.opacity = 0;
  document.getElementById('callback-overlay').style.visibility = 'hidden';
  document.getElementById('callback-popup').style.opacity = 0;
  document.getElementById('callback-popup').style.visibility = 'hidden';
}

function closeOverlay() {
  document.getElementById('callback-overlay').style.opacity = '0';
  document.getElementById('callback-overlay').style.visibility = 'hidden';

  const orderPopup = document.querySelector('div.callback-popup');
  if (orderPopup) {
    document.getElementById('callback-popup').style.opacity = '0';
    document.getElementById('callback-popup').style.visibility = 'hidden';
  }

  const callbackPopup = document.querySelector('div.callback-popup');
  if (callbackPopup) {
    document.getElementById('callback-popup').style.opacity = '0';
    document.getElementById('callback-popup').style.visibility = 'hidden';
  }
}

function goalCall() {
  ym(28518816, 'reachGoal', 'make-call');
}

function goalWhatsapp() {
  ym(28518816, 'reachGoal', 'whatsapp');
}

document.addEventListener('DOMContentLoaded', () => {
  // Callback form send
  jQuery('#callback-popup-form').submit(function () {
    const formData = jQuery(this).serialize();
    jQuery.ajax({
      type: 'POST',
      url: '/send-callback/',
      data: formData,
      success() {
        document.getElementById('callback-form').reset();
        document.getElementById('callback-result').style.display = 'block';
        document.getElementById('callback-result').style.visibility = 'visible';
        ym(28518816, 'reachGoal', 'callback-send');
      },
      error(XMLHttpRequest, textStatus, errorThrown) {
        console.log(XMLHttpRequest);
      },
    });
  });

  // Callback button
  const callBackButton = document.querySelector('a.callback-trigger');
  if (callBackButton) {
    callBackButton.addEventListener('click', callBackForm);
  }

  // Callback close
  const closeCallbackButton = document.querySelector('div.close-callback');
  if (closeCallbackButton) {
    closeCallbackButton.addEventListener('click', closeCallback);
  }

  // Close overlay
  const closeFormsOverlay = document.querySelector('div.callback-overlay');
  if (closeFormsOverlay) {
    closeFormsOverlay.addEventListener('click', closeOverlay);
  }

  // Make call
  if (document.getElementsByClassName('goal-call')) {
    const goalCallLinks = document.getElementsByClassName('goal-call');

    for (let i = 0, len = goalCallLinks.length; i < len; i += 1) {
      goalCallLinks[i].addEventListener('click', goalCall, false);
    }
  }

  // Make whatsapp
  if (document.getElementsByClassName('goal-whatsapp')) {
    const whatsappLinks = document.getElementsByClassName('goal-whatsapp');

    for (let i = 0, len = whatsappLinks.length; i < len; i += 1) {
      whatsappLinks[i].addEventListener('click', goalWhatsapp, false);
    }
  }
});
